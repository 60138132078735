import { CLEARANCE } from '@/utils/constants';

export default {
  data: () => ({
    clearance: CLEARANCE
  }),
  computed: {

  }
};
