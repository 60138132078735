<template>
  <div class="vx-row">
    <div class="centerx">
      <vs-popup class="holamundo" :title="`${modal.action} technician`" :active.sync="modal.active">
        <div class="vx-row" data-vv-scope="item-modal">
          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Name</label>
            <vs-input
              v-model="modal.form.name"
              class="w-full"
              name="name"
              :danger="errors.has('name')"
              :danger-text="errors.first('name')"
              v-validate="'required|min:1|max:30'"
              size="normal"
            />
          </div>
          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Password</label>
            <vs-input
              v-model="modal.form.password"
              class="w-full"
              name="password"
              :danger="errors.has('password')"
              :danger-text="errors.first('password')"
              v-validate="'min:5|max:20'"
              size="normal"
            />
          </div>
        </div>
        <vs-divider />
        <div class="vx-col w-full mt-5">
          <vs-button :disabled="this.errors.items.length !== 0" @click="upsertTechnician" :color="modal.action === 'create' ? 'success' : 'warning'" type="filled" class="w-full">
            {{ modal.action === 'create' ? 'Create New Technician' : 'Save changes' }}
          </vs-button>
        </div>
      </vs-popup>
    </div>
    <div class="vx-col w-3/4 mx-auto self-center">
      <vx-card>
        <div>
          <vs-input icon-after="true" icon-pack="feather" icon="icon-search" label-placeholder="Search" v-model="search.name" class="w-full no-icon-border is-label-placeholder" />
        </div>
        <br>
        <div class="vs-con-loading__container" id="div-with-loading">
          <vs-table stripe :data="technicians">
            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>Username</vs-th>
              <vs-th>Code</vs-th>
              <vs-th v-if="isSuperAdmin">
                Branch
              </vs-th>
              <vs-th v-if="isBranchAdminOrFinance">
                Actions
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="tr.id" v-for="(tr, indextr) in data" :data="tr" @click="() => loadEdit(indextr)">
                <vs-td :data="tr.name">
                  {{ tr.name }}
                </vs-td>

                <vs-td :data="tr.user">
                  {{ tr.user && tr.user.username || '-' }}
                </vs-td>

                <vs-td :data="tr.code">
                  {{ tr.code }}
                </vs-td>

                <vs-td v-if="isSuperAdmin">
                  <vs-chip size="small" color="primary">
                    {{ tr.branch.code }}
                  </vs-chip>
                </vs-td>

                <vs-td v-if="isBranchAdminOrFinance">
                  <feather-icon icon="EditIcon" svg-classes="w-5 h-5 hover:text-warning stroke-current" @click="() => loadEdit(indextr)" />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div slot="footer">
          <div class="vs-row">
            <vs-button color="success" @click="initForm" v-if="isBranchAdminOrFinance">
              Create Technician
            </vs-button>
            <vs-pagination :total="meta.total_page" v-model="meta.page" :max="7" />
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import TechnicianRepo from '@/repositories/technician_repo';
import Pagination from '@/utils/mixins/pagination';
import Clearance from '@/utils/mixins/clearance';
import { isEmpty } from '@/utils/helpers';

export default {
  mixins: [Pagination, Clearance],
  data: () => ({
    technicians: [],
    modal: {
      active: false,
      action: 'create',
      form: {
        id: null,
        name: null,
        username: null,
        password: null
      }
    }
  }),
  methods: {
    loadData({
      page = this.meta.page, per_page = this.meta.per_page, search, debounce = true
    }) {
      this.loadingTrue();
      const func = debounce ? TechnicianRepo.listDebounce : TechnicianRepo.list;
      return func(page, per_page, search)
        .then(({ data: { data: technicians, pagination: meta } }) => {
          this.technicians = technicians;
          this.meta = meta;
          this.loadingFalse();
        });
    },
    async upsertTechnician() {
      const noError = await this.checkModal();
      if (noError) {
        const payload = {
          name: this.modal.form.name,
          username: this.modal.form.username,
          password: this.modal.form.password
        };

        const actionIsCreate = this.modal.action === 'create';
        const action = actionIsCreate ? TechnicianRepo.create(payload) : TechnicianRepo.update(this.modal.form.id, payload);

        return action
          .then(() => {
            this.$vs.notify({
              title: 'Success',
              text: actionIsCreate ? 'New technician created' : 'Technician updated',
              color: 'success',
              position: 'bottom-center'
            });
            this.modal.active = false;
            this.clearForm();
            return this.loadData({});
          })
          .catch((err) => {
            this.$vs.notify({
              title: 'Whoops',
              text: err.message,
              color: 'danger',
              position: 'bottom-center'
            });
          });
      }
    },
    initForm() {
      this.clearForm();
      this.modal.action = 'create';
      this.modal.active = true;
    },
    clearForm() {
      this.modal.form = {
        id: null,
        name: null,
        username: null,
        password: null
      };
      this.$validator.reset();
    },
    loadEdit(index) {
      const technician = this.technicians[index];
      this.modal.form = {
        ...technician
      };
      this.modal.action = 'update';
      this.modal.active = true;
    },
    async checkModal() {
      await this.$validator.validate('name');
      return isEmpty(this.errors.items);
    },
    isEmpty
  },
  computed: {
    isBranchAdminOrFinance() {
      return [this.clearance.BRANCH_ADMIN, this.clearance.FINANCE].includes(this.$store.getters.getClearance);
    },
    isSuperAdmin() {
      return this.$store.getters.getClearance === this.clearance.SUPER_ADMIN;
    }
  }
};
</script>
